<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { ref, computed } from "vue-demi";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { useFindByPartId } from "../../utils/feathers-pinia/use-get-helpers";
import { usePartFamily } from "../../store/partFamily.pinia";
import { usePart } from "../../store/part.pinia";
import { computedRef } from "../../utils/composables";
import { useFind } from "feathers-pinia";

export default {
  name: "FamilyDetailAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: { AccordionRows },
  setup(props) {
    console.warn("FamilyDetailAccordion.vue");
    const partFamilyStore = usePartFamily();
    const partStore = usePart();

    // set part_id as the child_part_id of a part_family object
    const childPartFamilyParams = computedRef(() => {
      return {
        query: {
          child_part_id: props.node?.part_id,
        },
      };
    });

    // find part_family objects where part_id is the child_part_id
    const { items: childPartResults } = useFind({
      model: partFamilyStore.Model,
      params: childPartFamilyParams,
      // Never query, because the parent is executing the query for us (use local)
      queryWhen: computed(() => false),
    });
    // get the parent part_id of the node (child) part_id
    // i dont believe it is possible for this result.length > 1, but to be safe we will treat is as an array
    const childPartParentIds = computedRef(() => {
      return childPartResults.value.map((item) => item.part_id) ?? [];
    });

    // find all part_family objects where the part_id === the parent part_id of the node (child) part_id
    const parentPartParams = computedRef(() => {
      return {
        query: {
          part_id: { $in: childPartParentIds.value },
        },
      };
    });
    const { items: parentPartResults } = useFind({
      model: partFamilyStore.Model,
      params: parentPartParams,
    });

    const parentPartId = ref();

    // gather all related partids before finding all parts
    const relatedPartIds = computedRef(() => {
      // if the node part only has parent parts
      // childPartParentIds.value[0] is the parent part_id in this case
      if (parentPartResults?.value?.length) {
        parentPartId.value = childPartParentIds.value[0];
        return (
          parentPartResults.value
            .map((item) => item.child_part_id)
            .concat(childPartParentIds.value[0]) ?? []
        );
      }
      // if the node part only has child parts
      // props.node?.part_id is the parent part_id in this case
      else if (partFamilyResults?.value?.length) {
        parentPartId.value = props.node?.part_id;
        return (
          partFamilyResults?.value
            .map((item) => item.child_part_id)
            .concat(props.node?.part_id) ?? []
        );
      } else return [];
    });

    // find all related parts
    const relatedPartQuery = computedRef(() => {
      return {
        query: {
          id: { $in: relatedPartIds.value },
          $sort: { part_number: 1 },
        },
      };
    });
    const { items: relatedPartResults, isPending: isPendingRelatedPart } =
      useFind({
        model: partStore.Model,
        params: relatedPartQuery,
      });
    // filtering out just child parts
    const filteredPartResults = computedRef(() => {
      return (
        relatedPartResults.value.filter(
          (item) => item.id != parentPartId.value
        ) ?? []
      );
    });
    // filtering out just the parent part
    const parentPartResult = computedRef(() => {
      return (
        relatedPartResults.value.filter(
          (item) => item.id === parentPartId.value
        ) ?? []
      );
    });

    // set parent part_id as either the child_part's parent,
    // or when the part_id of the node (when the node part is the parent part)
    const partId = computed(() =>
      childPartParentIds?.value?.length
        ? childPartParentIds?.value[0]
        : props?.node?.part_id
    );
    const { items: partFamilyResults, isPending: isPendingPartFamily } =
      useFindByPartId(partId, partFamilyStore);

    // only one result needs to have print_children_tickets so if one does, return true
    const hasPrintChildTickets = computed(() => {
      return partFamilyResults.value.filter(function (res) {
        if (res.print_children_tickets) return res;
      });
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        partFamily: isPendingPartFamily.value,
        childParts: isPendingRelatedPart.value,
        partFamilyBoolean: isPendingPartFamily.value,
        arrayItem: isPendingRelatedPart.value || isPendingPartFamily.value,
        parentPart: isPendingRelatedPart.value,
      };
    });

    const entities = computed(() => {
      return {
        partFamily: partFamilyResults.value,
        childParts: filteredPartResults.value,
        partFamilyBoolean: {
          hasTickets: hasPrintChildTickets?.value?.length > 0,
        },
        parentPart: parentPartResult.value[0],
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow("Family Detail", [
          entityRowField("parentPart", "part_number", {
            label: "Parent Part",
            colAttributes: {
              cols: 12,
              sm: 3,
              class: "px-2",
            },
          }),
          entityRowField("partFamilyBoolean", "hasTickets", {
            label: "Print Children Tickets",
            type: "checkbox",
            colAttributes: {
              cols: 12,
              sm: 9,
              class: "px-2",
            },
          }),
        ]),
        entityRow(
          "Child Details",
          [
            entityRowField("arrayItem", "part_number", {
              label: "Child Part Number",
              colAttributes: {
                cols: 12,
                sm: 3,
                class: "px-2",
              },
            }),
            entityRowField("arrayItem", "description", {
              label: "Part Description",
              colAttributes: {
                cols: 12,
                sm: 7,
                class: "px-2",
              },
            }),
            entityRowField(
              (rowProps) => {
                return rowProps.entities.partFamily.find(
                  (item) => item.child_part_id === rowProps.arrayItem.id
                );
              },
              "quantity",
              {
                label: "Quantity",
                colAttributes: {
                  cols: 12,
                  sm: 2,
                  class: "px-2",
                },
              }
            ),
            entityRowField(
              (rowProps) => {
                return rowProps.entities.partFamily.find(
                  (item) => item.child_part_id === rowProps.arrayItem.id
                );
              },
              "ignore_from_restock_calculation",
              {
                label: "Ignore from Restock Calculation",
                type: "checkbox",
                colAttributes: {
                  cols: 12,
                  sm: 12,
                  class: "px-2",
                },
              }
            ),
          ],
          {
            rowType: "array",
            arrayPath: "childParts",
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
